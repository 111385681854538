import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import {
  WoosaPromiseIconGridSection,
  AdjustableBaseIntroSection,
  AdjustableBaseBenefitSection,
  AdjustableBaseReviewHeroSection,
  AdjustableBaseUpsellGridSection,
  AdjustableBaseAccordionSection,
  AppointmentSection,
  AdjustableBaseIntroGallerySection,
  AdjustableBaseSmartAppSection,
  AdjustableBaseProductSection,
  AdjustableBaseProductFixedBottomBar,
} from '../components/sections';

const BedFramesPage: React.FC = () => (
  <Layout>
    <SEO
      title="Adjustable Bed Frame & Base | Split Mattress Singapore"
      titleTemplate=""
      description="The Woosa Adjustable Base II is an automatic adjustable bed frame with split mattress customisation. Made with German engineering and touch memory options."
      meta={[
        {
          name: 'keywords',
          content:
            'adjustable bed frame, split mattress, automatic bed frame, adjustable bed base singapore, split mattress singapore, split king mattress, adjustable bed with massage function, vibrating adjustable bed, dual side adjustable beds',
        },
      ]}
    />
    <AdjustableBaseProductSection />
    <AdjustableBaseIntroSection />
    <AdjustableBaseIntroGallerySection />
    <AdjustableBaseSmartAppSection />
    <AdjustableBaseBenefitSection />
    <AdjustableBaseAccordionSection />
    <AppointmentSection />
    <AdjustableBaseReviewHeroSection />
    <AdjustableBaseUpsellGridSection />
    <WoosaPromiseIconGridSection version="v2" />
    <AdjustableBaseProductFixedBottomBar />
  </Layout>
);

export default BedFramesPage;
